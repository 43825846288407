<template>
  <div class="ifrm_jump">
    <iframe
      :src="url"
      frameborder="0"
      class="height100"
      style="width: 100%"
      ref="maidIframe"
      id="maidIframe"
    ></iframe>
  </div>
</template>
<script>
export default {
  data() {
    return {
      url:
        this.$route.query.url +
        (this.$route.query.url.indexOf('?') > -1 ? '&' : '?') +
        'v=' +
        Date.parse(new Date()),
      time: 0
    }
  },
  created() {
    this.url =
      this.$route.query.url +
      (this.$route.query.url.indexOf('?') > -1 ? '&' : '?') +
      'v=' +
      Date.parse(new Date())
    // if (localStorage.isYZJ === "1")
    //   this.url = this.url.replace("weixinlocal", "yunzhijialocal");
    // console.log(this.url, 'this.urlthis.urlthis.urlthis.url')
    this.time = this.$route.query.time
  },
  methods: {},
  watch: {
    // 监视搜索词变化
    '$route.query.url': {
      immediate: true,
      handler() {
        this.url =
          this.$route.query.url +
          (this.$route.query.url.indexOf('?') > -1 ? '&' : '?') +
          'v=' +
          Date.parse(new Date())
        // if (localStorage.isYZJ === "1")
        //   this.url = this.url.replace("weixinlocal", "yunzhijialocal");
        // console.log(this.url, 'this.urlthis.urlthis.urlthis.url')
        this.time = this.$route.query.time
      }
    }
  }
}
</script>
<style scoped lang="less">
.ifrm_jump {
  width: 100%;
  height: calc(100% - 1.89333rem);
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>